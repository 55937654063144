import $ from 'jquery';
//import jQuery from 'jquery';
//import 'bootstrap/js/dist/util';
//import 'bootstrap/js/dist/dropdown';
import { Tooltip, Toast, Popover } from 'bootstrap';

import './scss/app.scss';

window.$ = $;

$(document).ready(function(){
	
	// Navigation levels
	$('.navbar .dropdown').hover(function() {
		$(this).find('.dropdown-menu').first().stop(true, true).slideDown(1);
	}, function() {
		$(this).find('.dropdown-menu').first().stop(true, true).slideUp(1);
	});

	$('.navbar .dropdown > a').click(function(){
		location.href = this.href;
	});

});


// Say Hi!!!
function sayHiNow(){
	let el_autohide = document.querySelector('.scroll-cta');

	if(el_autohide){
		let last_scroll_top = 50;
		window.addEventListener('scroll', function() {
			let scroll_top = window.scrollY;
		
			if(scroll_top < last_scroll_top) {
				setTimeout(function(){
					el_autohide.classList.remove('scrolled-down');
					el_autohide.classList.add('scrolled-up');
				}, 200);
			}
			else {
				setTimeout(function(){
					el_autohide.classList.remove('scrolled-up');
					el_autohide.classList.add('scrolled-down');
				}, 200);
			}

			last_scroll_top = scroll_top;
		}); 
		// window.addEventListener
	}
	// if
}

sayHiNow();